<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  props: {
    variety: { type: Object, required: true },
    selectedVariety: { type: Number, required: true },
  },

  data() {
    return {
      selectInfo: false,
      loading: false,
    }
  },

  computed: {
    ...mapGetters('farms', ['currentFarmId']),
    ...mapGetters('crops', ['currentCrop', 'isSoybean', 'isWheat']),

    cropIcon() {
      const cropName = this.currentCrop.name.toLowerCase()
      return {
        alt: cropName,
        src: require(`@/assets/icons/ic-${cropName}.svg`),
      }
    },
    notRecommended() {
      return this.variety.recomendado == 0
    },
    disabled() {
      return this.variety.recomendado == 0 && this.isWheat
    },
    varietyAdded() {
      return this.variety.added
    },
  },

  methods: {
    ...mapActions('cultivares', ['createVariety', 'excluirCultivar']),

    showInfo() {
      this.$emit('selectVariety', this.variety.semente_id)
      this.selectInfo = true
    },
    async add() {
      try {
        this.loading = true
        const response = await this.createVariety({
          seedId: this.variety.semente_id,
          farmId: this.currentFarmId,
        })

        this.$emit('addVariety', {
          varietyId: response.data.id,
          seedId: this.variety.semente_id,
        })
        this.$root.$emit(
          'notify',
          'success',
          this.$t('cultivar.salvar.sucesso')
        )
        this.loading = false
      } catch (e) {
        this.loading = false
      }
    },
    async remove() {
      try {
        this.loading = true
        await this.excluirCultivar({
          id: this.variety.id,
          farmId: this.currentFarmId,
        })
        this.$emit('removeVariety', this.variety.id)
        this.loading = false
        this.$root.$emit(
          'notify',
          'success',
          this.$t('cultivar.excluir.sucesso')
        )
      } catch (e) {
        this.loading = false
      }
    },
  },

  watch: {
    selectedVariety(value) {
      if (value != this.variety.semente_id) {
        this.selectInfo = false
      }
    },
    variety() {
      this.loading = false
    },
  },
}
</script>

<template>
  <v-card @click="showInfo" :class="{ active: selectInfo }">
    <div class="variety">
      <div class="crop">
        <div>
          <v-img width="13px" :alt="cropIcon.alt" :src="cropIcon.src" />
        </div>
        <p>
          {{ $t(currentCrop.messageKey).toUpperCase() }}
        </p>
      </div>
      <div class="seed">
        {{ variety.cultivar }}
      </div>
    </div>
    <div class="right-align">
      <div class="not-recommended">
        <div v-if="notRecommended" class="chip">
          <v-icon size="14" color="#F23528">mdi-thumb-down</v-icon>
          {{ $t('variety.not_recommended').toUpperCase() }}
        </div>
      </div>
      <div class="owner">
        <v-icon size="14" color="#AAB2A9">mdi-office-building-outline</v-icon>
        {{ variety.asset_owner_name }}
      </div>
      <div class="gmr" v-if="isSoybean">
        <v-icon size="14" color="#AAB2A9">mdi-poll</v-icon>
        {{ $t('cultivar.gmr') }}: {{ variety.grupo }}
      </div>
    </div>
    <div class="options" v-if="!disabled">
      <v-btn
        v-if="varietyAdded"
        class="remove"
        depressed
        :loading="loading"
        @click.stop="remove()"
      >
        <v-icon color="#788476" size="18"> mdi-delete </v-icon>
      </v-btn>
      <v-btn
        v-else
        class="add"
        depressed
        :loading="loading"
        @click.stop="add()"
      >
        <v-icon color="#39AF49" size="18"> mdi-plus </v-icon>
      </v-btn>
    </div>
  </v-card>
</template>

<style lang="scss" scoped>
.v-card {
  font-family: 'Source Sans Pro';
  padding: 12px 24px 12px 16px;
  border: 1px solid #e6e9e6;
  border-radius: 8px;
  width: CALC(100% - 25px);
  display: grid;
  grid-template-areas: 'variety right-align options';
  grid-template-columns: auto auto 30px;
  cursor: pointer;
}

.right-align {
  grid-area: right-align;
  display: flex;
  justify-content: flex-end;
  gap: 16px;
}
.variety {
  grid-area: variety;
}

.owner,
.gmr,
.not-recommended,
.options {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.v-card.active {
  border: 1px solid #39af49;
}
.v-icon {
  padding-right: 6px;
}
.v-image {
  width: 13px;
}
.v-input {
  margin: 0px;
  padding: 0px;
}
::v-deep .v-input__control {
  height: 0px;
}

.options {
  width: 36px;
  margin-left: 4px;
}

.options .v-icon {
  padding: 6px;
}
.options .v-progress-circular {
  margin: 6px;
}
.options .add,
.options .remove {
  width: 30px;
  min-width: 30px;
  border-radius: 4px;
  border: 1px solid #e6e9e6;
}
.options .remove {
  background: #f5f5f5;
}
.chip {
  padding: 4px 12px;
  border-radius: 50px !important;
  font-weight: 700;
  font-size: 12px;
  height: 28px;
  width: fit-content;
}
.not-recommended .chip {
  background-color: #ffe1df;
  color: #f23528;
}
.variety-info {
  font-weight: 500;
  font-size: 14px;
  color: #5b6459;
  justify-content: right;
  gap: 16px;
}
.crop {
  display: flex;
  align-items: center;
}
.crop p {
  font-weight: 700;
  font-size: 12px;
  color: #aab2a9;
  padding-left: 6px;
}
.seed {
  font-weight: 500;
  font-size: 18px;
  color: #1a2b46;
  font-family: 'Rubik';
}

@media (max-width: 960px) {
  .v-card {
    grid-template-areas:
      'variety variety variety variety options'
      'gmr not-recommended not-recommended not-recommended not-recommended'
      'owner owner . . .';
    grid-template-columns: auto auto auto auto 0px;
    padding: 12px 16px;
    width: 100%;
    row-gap: 4px;
  }
  .right-align {
    display: contents;
  }
  .right-align .not-recommended {
    grid-area: not-recommended;
  }
  .right-align .options {
    grid-area: options;
  }
  .owner {
    grid-area: owner;
    justify-content: flex-start;
  }
  .gmr {
    grid-area: gmr;
    justify-content: flex-start;
  }

  .gmr,
  .not-recommended {
    margin-top: 6px;
  }

  .options {
    width: 0px;
    margin-left: 0px;
  }
}
</style>
