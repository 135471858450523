<template>
  <permission-failed-message v-if="!hasPermission(this.$p.variety.list)" />
  <div v-else>
    <variety-header />
    <div class="df-flex-l df-flex-col variety-home">
      <farm-disabled v-if="!farms.length" />
      <div v-else>
        <div class="df-flex-l">
          <crop-tabs />
          <div class="df-col-7 df-col-12-m df-col-12-t">
            <loader v-if="loading" />
            <div v-else>
              <sort-and-filter
                :varieties="varieties"
                @updateVarietiesList="updateVarietiesList"
              />
              <variety-list
                class="variety-list__height"
                :varieties="sortedAndFilteredVarieties"
                :selected-variety="selectedVariety"
                @selectVariety="selectVariety"
                @removeVariety="removeVariety"
                @addVariety="addVariety"
              />
            </div>
          </div>
          <div v-if="isDSMobile || isDSTablet">
            <v-dialog v-model="mobileVarietyInfoDialog" fullscreen>
              <v-card>
                <v-card-title>
                  <button @click="closeMobile">
                    <v-icon size="18" color="#AAB2A9">mdi-close</v-icon>
                  </button>
                </v-card-title>
                <v-card-text>
                  <variety-info :variety="selectedVarietyInfo" />
                </v-card-text>
              </v-card>
            </v-dialog>
          </div>
          <div v-else class="df-col-5 home__variety-info">
            <variety-info
              v-if="selectedVariety != 0"
              :variety="selectedVarietyInfo"
            />
            <div v-else class="home__no-variety">
              <v-img
                alt="warning"
                max-height="90"
                src="icons/ic-warning-green.svg"
                width="90"
              />
              <p>{{ $t('variety.select_variety') }}</p>
            </div>
          </div>
        </div>
        <no-available-variety
          v-if="!sementes.length && !loading"
          :crop="$t(this.currentCrop.messageKey)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import CropTabs from '@/components/Crops/CropTabs.vue'
import FarmDisabled from '@/components/Banners/FarmDisabled.vue'
import LayoutMixin from '@/components/LayoutMixin.vue'
import Loader from '@/components/Loaders/Loader.vue'
import PermissionMixin from '@/components/Permission/PermissionMixin.vue'
import NoAvailableVariety from '@/pages/cultivares/NoAvailableVariety.vue'
import SortAndFilter from '@/pages/cultivares/SortAndFilter.vue'
import VarietyHeader from '@/pages/cultivares/VarietyHeader.vue'
import VarietyList from '@/pages/cultivares/VarietyList.vue'
import VarietyInfo from '@/pages/cultivares/VarietyInfo.vue'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'VarietyHome',

  mixins: [PermissionMixin, LayoutMixin],

  components: {
    CropTabs,
    FarmDisabled,
    Loader,
    NoAvailableVariety,
    SortAndFilter,
    VarietyHeader,
    VarietyList,
    VarietyInfo,
  },

  data() {
    return {
      loading: false,
      selectedVariety: 0,
      mobileVarietyInfoDialog: false,
      sortedAndFilteredVarieties: [],
      varieties: [],
    }
  },

  watch: {
    currentCrop: {
      handler() {
        this.loadVarieties()
      },
      immediate: true,
    },
    sementes() {
      this.varieties = this.sementes
    },
  },

  computed: {
    ...mapGetters('farms', ['farms', 'currentFarmId']),
    ...mapGetters('crops', ['currentCrop']),
    ...mapGetters('cultivares', ['sementes']),
    selectedVarietyInfo() {
      return this.sortedAndFilteredVarieties.find(
        (item) => item.semente_id === this.selectedVariety
      )
    },
  },

  methods: {
    ...mapActions('cultivares', ['getSeedsBySelectedCrop']),
    updateVarietiesList(varieties) {
      this.sortedAndFilteredVarieties = varieties
    },
    async loadVarieties() {
      this.loading = true
      this.selectedVariety = 0
      await this.getSeedsBySelectedCrop(this.currentFarmId)
      this.loading = false
    },
    async addVariety(varietyObject) {
      this.varieties = this.varieties.map((obj) => {
        if (obj.semente_id === varietyObject.seedId) {
          const newObj = { ...obj }
          newObj.added = true
          return newObj
        }
        return obj
      })
    },
    async removeVariety(varietyId) {
      this.varieties = this.varieties.map((obj) => {
        if (obj.id === varietyId) {
          const newObj = { ...obj }
          newObj.added = false
          return newObj
        }
        return obj
      })
    },
    selectVariety(variety) {
      this.selectedVariety = variety
      this.mobileVarietyInfoDialog = true
    },
    closeMobile() {
      this.mobileVarietyInfoDialog = false
    },
  },
}
</script>

<style lang="scss" scoped>
.variety-list__height {
  max-height: calc(100vh - 220px);

  @include d(m) {
    max-height: calc(100vh - 200px);
  }
}
.variety-home {
  max-width: 1472px;
  margin: 0px auto;
  padding: 0px 16px 40px 16px;

  @include d(m) {
    padding: 0px 8px 12px 8px;
  }
  .home__variety-info {
    min-height: 100%;
    background: #fff;
    border: 1px solid #e6e9e6;
    border-radius: 8px;
    padding: 24px 16px;
    text-align: center;
  }
  .home__no-variety {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  .home__no-variety p {
    color: #1a2b46;
    font-family: 'Rubik';
    font-weight: 500;
    font-size: 16px;
    padding: 12px 20%;
  }
}
@media (max-width: 960px) {
  .v-card__title {
    display: flex;
    justify-content: flex-end;
  }
  .v-card__text {
    padding: 0px 10px;
  }
}
</style>
